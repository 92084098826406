.vaultsGrid{
    display: grid !important;
    grid-template-columns: 2.7fr 1fr 1fr 1fr !important;
    // grid-column: 3 / span 8 !important;
    padding: 10px 30px;
    background-color: #FAFAFA;
    border-bottom: solid 0.5px #E7E7E7;
    & > div:not(:first-child){
        text-align: right;
        font-weight: 600;
    }
    & > div{
        font-weight: 600;
    } 
}
.vaultsGrid1{
    display: grid !important;
    grid-template-columns: 2.7fr 1fr 1fr 1fr !important;
    padding: 10px 50px;
    background-color: #FAFAFA;
    border-bottom: solid 0.5px #E7E7E7;
    font-size: 11px;
   
    & > div:not(:first-child){
        text-align: right;
        font-weight: 600;
    }
    & > div{
        font-weight: 600;
    } 
}


.vaultsUsersGrid{
    display: grid !important;
    grid-template-columns: 2.7fr 1fr 1fr 1fr !important;
    padding: 10px 30px;
    border-bottom: solid 0.5px #E7E7E7;
    height: 90px;
    .title{
        font-size: 15px !important;
        font-weight: 700;
        color: #18191D;
    }
    .subtitle{
        font-size: 12px !important;
        font-weight: 400;
        color: #18191D;
    }
    
    .subtitle1{
        font-size: 16px !important;
        font-weight: 400;
        color: #18191D;
    }
    & > div:not(:first-child){
        /* text-align: right; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: end; 
    }
    &:hover{
        & > div{
            -ms-transform: scale(1.02); /* IE 9 */
            -webkit-transform: scale(1.02); /* Safari 3-8 */
            transform: scale(1.02); 
            transition: 0.3s all ease-in-out;
            opacity: 1 !important;
        }
        cursor: pointer;
         background: whitesmoke;
         transition: 0.3s all ease-in-out;
    }
}

.vaultsUsersGrid1{
    display: grid !important;
    grid-template-columns: 2.7fr 1fr 1fr 1fr !important;
    padding: 10px 30px;
    border-bottom: solid 0.5px #E7E7E7;
    height: 100px;
    .title{
        font-size: 15px !important;
        font-weight: 700;
        color: #18191D;
    }
    .subtitle{
        font-size: 15px !important;
        font-weight: 400;
        color: #18191D;
    }
    
    .subtitle1{
        font-size: 14px !important;
        font-weight: 400;
        color: #18191D;
    }
    & > div:not(:first-child){
        /* text-align: right; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: end; 
    }
    &:hover{
        & > div{
            -ms-transform: scale(1.02); /* IE 9 */
            -webkit-transform: scale(1.02); /* Safari 3-8 */
            transform: scale(1.02); 
            transition: 0.3s all ease-in-out;
            opacity: 1 !important;
        }
        cursor: pointer;
         background: whitesmoke;
         transition: 0.3s all ease-in-out;
    }
}

.imgshadow{
//     border-radius: 50%;
// background: #ffffff;
// box-shadow:  20px 20px 60px #d9d9d9,
//              -20px -20px 60px #ffffff;
border-radius: 50%;
background: #ffffff;
box-shadow:  5px 5px 54px #d9d9d9,
             -5px -5px 54px #ffffff;
}

.nav-user{
    font-size: 15px;
    font-weight: 800;
    color: #18191D;
    border: solid 1px #e7e7e780;
    border-width: 0px 0px 0px 1px;
    padding: 0px 20px;
    display: flex;
    align-items: center;
  }
  .nav-user:hover{
    cursor: pointer;
  }

  .vault-label{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 15px;

color: #18191D;
  }
  
.vault-price{
font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 30px;
color: #18191D;
padding-top: 5px;
  }

.vault-price-subtitle{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  color: #18191D;
  }

  .dropdown-selector{
    /* width: 160px; */
    min-width: 160px;
    height: 42px;
    padding:0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 0.5px solid #EEEEEE;
border-radius: 5px;
font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 12px;
line-height: 12px;
color: #18191D;
cursor: pointer;
  }
  
  .deposit-button{
    width: 111px;
    height: 42px;
    left: 1180px;
    top: 150px;
    background: #50C7AD;
    border: 0.5px solid #50C7AD;
    border-radius: 5px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #FFFFFF !important;
    cursor: pointer;
  }
 
  .withdraw-button{
    width: 111px;
    height: 42px;
    left: 1301px;
    top: 150px;
    color: white !important;
    background: #EA0F0F;
    border: 0.5px solid #EA0F0F;
    border-radius: 5px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    
    
    color: #FFFFFF;
  }
  .filter1:hover{
    opacity: 1 !important;
  }

  .coin-card1{
    padding:30px 40px;
    border-bottom: 0.5px solid #EEEEEE;
    /* border-width: 0px ; */
    /* transform: matrix(1, 0, 0, -1, 0, 0); */
    font-size: 17px;
    font-weight: 700;
    color: #18191D;
    cursor: pointer;
  }
  .notes{
    /* width: 72px; */
height: 21px;
border: 0.5px solid lightgray;
border-radius: 5px;
font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 10px;
display: flex;
align-items: center;
justify-content: center;
text-align: center;
padding:5px;
color: #18191d9d;
  }
  .notes:hover{
cursor: pointer;
      /* width: 72px; */
  height: 21px;
  border: 0.5px solid #EEEEEE;
  border-radius: 5px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #18191D;
  color: white;

  }

  .asset-name{
    font-family: 'Montserrat';
    font-size: 30px;
    font-weight: 600;
    color: #292929;
  }

  //Switch CSS


//Switch CSS

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.withbalance{
  font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 12px;
line-height: 11px;
display: flex;
align-items: center;
text-align: center;

color: #5F6163;
padding-top: 10px;

}


.coin-card{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
  border: 0.5px solid #E5E5E5;
  border-radius: 15px;
  height: 80px;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 700;
}
.coin-card:hover{
  cursor: pointer;
  background-color: aliceblue;
}

.coin-card-selected{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
  border: 0.5px solid #E5E5E5;
  border-radius: 15px;
  height: 80px;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  background-color: aliceblue;
}
