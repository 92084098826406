@import "../../static/scss/mixin.scss";

.grid4 {
  // position: relative;
  // height: 380px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* grid-gap: 2rem; */
  /* padding: 0px 30px; */
  /* background-color: #e8e8e2; */
  /* background-color: #E5E5E5; */
  background-color: #FAFAFA;

}

.heroText {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  background: linear-gradient(275.12deg, #277EFB 7.36%, #B580F2 140.3%);
  font-size: 60px;
  line-height: 85px;
  /* or 142% */

  display: flex;
  /* align-itemwas: center; */

  color: #FFFFFF;


}

.clickButton {

  width: 268.26px;
  height: 66.94px;

  border: 2px solid #FFFFFF;
  border-radius: 10px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-size: 25px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #FFFFFF;
  display: flex;
  justify-content: center;
  @include btn-animate(white);
}



.inputLogin {
  width: 322px;
  padding: 17px 27px;
  border-radius: 35px;
  border: 1px solid #D9D9D9;
  background-color: transparent;
  font-weight: 600;
  font-size: 18px;

  // font-weight: 700;
  &::placeholder {
    //  font-weight: 700;
    opacity: 0.5;
  }
}

.btnLogin {
  width: 322px;
  height: 60px;
  background: linear-gradient(275.12deg, #277EFB 7.36%, #B580F2 140.3%);
  opacity: 0.9;
  border-radius: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  cursor: pointer;
  @include btn-animate(white);
}

.clickbtn {
  text-decoration: none;
  font-weight: 500;
  cursor: pointer;
  color: #5F6163;
}

.forgotbtn {
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #182542;
  opacity: 0.25;
  margin-top: 20px;
  justify-content: end;
  margin-bottom: 70px;
  width: 322px;
}