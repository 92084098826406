



.navs-disp {
    display: flex;
    font-family: Montserrat;
}

.navs-data {
    width: 50%;
    text-align: center;
    height: 55px;
    align-items: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-right: 0.5px solid #E7E7E7;
    border-bottom: 0.5px solid #E7E7E7;
    font-weight: 400;
    font-size: 16px;
}

.disp-nav {
    font-weight: 400;
    font-size: 16px;
}

.active-tab {
    font-weight: 700;
    font-size: 16px;
    background: #FAFAFA;
}

.profile-data {
    padding: 45px 30px;
}

.user-profile {
    width: 49px;
    height: 49px;
    border-radius: 30px;
}

.prof-align {
    display: flex;
}

.select-name {
    font-weight: 700;
    font-size: 25px;
    color: #212224;
}

.drawer-crumb {
    font-weight: 400;
    font-size: 9px;
    color: #212224;
    margin-top: -22px;
    cursor: pointer;
}

.prof-text {
    margin-left: 10px;
}

.active-crumb {
    font-weight: 700;
    text-decoration: underline;
}

.back-right,
.close-img {
    border: 0.5px solid #E7E7E7;
    width: 40px;
    height: 40px;
    padding: 10px;
    cursor: pointer;

}

.icon-imgs{
    overflow: hidden;
    position: relative;
    height: 40px;
}

.icon-imgs::after {
    background: #212224;
    content: "";
    height: 200px;
    left: -105px;
    opacity: 1;
    position: absolute;
    top: -70px;
    width: 20px;
    transition: all .55s cubic-bezier(.19, 1, .22, 1);
    -webkit-transform: rotate(35deg);
    transform: rotate(35deg);
    z-index: -10;
}

.icon-imgs:hover::after {
    left: 155%;
    transition: all .55s cubic-bezier(.19, 1, .22, 1);
    z-index: 10;
}

.icons-sty {
    margin-left: auto;
    margin-top: 8px;
    display: flex;
}

.title-text {
    font-weight: 600;
    font-size: 16px;
    color: #212224;
}

.user-title {
    margin-top: 50px;
}

.usertyp-border {
    border: 0.5px solid #E7E7E7;
    display: flex;
    height: 83px;
    align-items: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

// .usertyp-border::after {
//     background: #212224;
//     content: "";
//     height: 200px;
//     left: -105px;
//     opacity: 1;
//     position: absolute;
//     top: -95px;
//     width: 40px;
//     transition: all 0.55s cubic-bezier(0.19, 1, 0.22, 1);
//     transform: rotate(35deg);
//     z-index: -10;
// }

// .usertyp-border:hover::after {
//     left: 155%;
//     transition: all .55s cubic-bezier(.19, 1, .22, 1);
//     z-index: 10;
// }

.usertyp-border:hover{
    background: #e5e5e5;
    padding-left: 10px;
    transition: 0.4s all ease;
}

.type-txt {
    font-weight: 500;
    font-size: 15px;
    color: #212224;
    margin-top: 16px;
    margin-left: 10px;
}

.type-img {
    margin-left: 30px;
}

/// New Global Style

.right-drawer-hidden {
    position: absolute;
    background: white;
    top: 123px;
    right: 0;
    overflow: hidden;
    width: 450px;
    border-left: 1px solid #e5e5e5;
    margin-top: -1px;
    transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    background: white;
    z-index: 1;
    transform: TranslateX(100%);
}

.right-drawer-visible {
    position: absolute;
    background: white;
    top: 123px;
    right: 0;
    overflow: hidden;
    width: 450px;
    border-left: 1px solid #e5e5e5;
    margin-top: -1px;
    transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    background: white;
    z-index: 1;
    transform: TranslateX(0%);
}

.backButton{
    width: 39px;
    height: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.5px solid #E7E7E7;
    cursor: pointer;
}

.breadcrumb{
    // font-weight: bold;
    font-size: 13px;
    padding-bottom: 40px;
    .crumbs{
      &:hover{
        font-weight: 600;
        cursor: pointer;
      }
    }
    .crumbSelected{
        text-decoration: underline;
        font-weight: 600;
    }
    
  }

  .breadcrumbSelected{
    font-weight: bold;
    font-size: 13px;
    padding-bottom: 40px;
    .crumbs{
      &:hover{
        text-decoration: underline;
        cursor: pointer;
      }
    }
    
  }

// .breadcrumb{
//     font-family: 'Montserrat';
//     font-style: normal;
//     font-weight: 400;
//     font-size: 10px;
//     line-height: 12px;
//     display: flex;
//     align-items: center;
//     padding-top: 6px;
// }
// .breadcrumbSelected{
//     font-family: 'Montserrat';
//     font-style: normal;
//     font-weight: 700;
//     font-size: 10px;
//     line-height: 12px;
//     display: flex;
//     align-items: center;
//     text-decoration-line: underline;
//     padding-top: 6px;
// }

.sidebarTitle{
    padding-top: 20px;
    padding-bottom: 20px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #464B4E;
}

.sidebarCard{
    border: 0.5px solid #E7E7E7;
    padding:30px;
    font-weight: 500;
    font-size: 15px;
    display: flex;
    align-items: center;
    margin-top: 20px;
    position: relative;
    &:hover{
        background: whitesmoke;
        padding-left: 40px;
        transition: 0.2s all ease;
        cursor: pointer;
    }
}

.sidebarCardDisabled{
    border: 0.5px solid #E7E7E7;
    padding:30px;
    font-weight: 500;
    font-size: 15px;
    display: flex;
    align-items: center;
    margin-top: 20px;
    opacity: 0.5;
}

@media only screen and (max-width: 600px) {
    .right-drawer-hidden {
        position: absolute;
        background: white;
        top: 123px;
        right: 0;
        overflow: hidden;
        width: 100vw;
        border-left: 1px solid #e5e5e5;
        margin-top: -1px;
        transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
        background: white;
        z-index: 1;
        transform: TranslateX(100%);
    }
    
    .right-drawer-visible {
        position: absolute;
        background: white;
        top: 132px;
        right: 0;
        overflow: hidden;
        width: 100vw;
        border-left: 1px solid #e5e5e5;
        margin-top: -1px;
        transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
        background: white;
        z-index: 1;
        transform: TranslateX(0%);
    }
  }