
.top{
    display: flex;
    height: 100vh;
    justify-content: space-between;
}
.grid{
    position: relative;
    height: 320px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 2rem;
    padding: 0px 30px;
    /* background-color: #e8e8e2; */
    /* background-color: #E5E5E5; */
    background-color: #FAFAFA;
    padding-bottom: 20px;
    padding-top: 20px;
}
.cell{
    
    background-color: white;
    padding: 1.5rem;
    border-radius: 10px;
    cursor: pointer;
    bottom: 0;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    /* width: 264px;
    height: 103px; */
    
}
.cell:hover{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.cell-header{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 800;
font-size: 20px;
line-height: 160%;
color: #18191D;
/* or 40px */

display: flex;
align-items: center;
letter-spacing: 0.002em;
}
.cell-subheader{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 160%;
    /* or 19px */
    color: #18191D;
    display: flex;
    align-items: center;
    letter-spacing: 0.002em;
    
 
}
.arrow-left{
    position: absolute;
    left:0.5vw;
    top: 150px;  
}
.arrow-right{
    position: absolute;
    right:0.5vw;
    top: 150px;
    
}

/* botton section */



.grid2{
    position: relative;
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-gap: 2rem;
    border-top: solid 0.5px #E7E7E7;
    /* padding: 10px 30px; */
    background-color: white;
    /* height: window.inner-300px; */
    /* padding-bottom: 30px; */
    /* grid-auto-rows: minmax(min-content, max-content); */

}
.grid21{
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
    border-top: solid 0.5px #E7E7E7;
    /* padding: 10px 30px; */
    background-color: white;
    /* padding-bottom: 30px; */
}
.tabHover{
    opacity: 0.5;
}
.tabHover:hover{
    -ms-transform: scale(1.05); /* IE 9 */
      -webkit-transform: scale(1.05); /* Safari 3-8 */
      transform: scale(1.05); 
      transition: 0.3s all ease-in-out;
      opacity: 1 !important;
}

.skeleton .line {
    height: 12px;
    margin-bottom:6px;
    border-radius: 2px;
    background: rgba(130, 130, 130, 0.2);
    background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
    background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
    background-size: 800px 100px;
    animation: wave-lines 2s infinite ease-out;
}

@keyframes wave-lines {
    0% {
        background-position: -468px 0;
    }
     100% {
        background-position: 468px 0;
    }
}

.coin-button{
    cursor: pointer;

}
.coin-button:hover{
    background-color: rgba(24, 25, 29, 0.1);
    /* color: white; */
    -ms-transform: scale(1.05); /* IE 9 */
      -webkit-transform: scale(1.05); /* Safari 3-8 */
      transform: scale(1.05); 
      transition: 0.3s all ease-in-out;
      opacity: 1 !important;
}
.coin-card{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
    border: 0.5px solid #E5E5E5;
    border-radius: 15px;
    height: 80px;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 700;
}
.coin-card:hover{
    cursor: pointer;
    background-color: aliceblue;
}

.coin-card-selected{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
    border: 0.5px solid #E5E5E5;
    border-radius: 15px;
    height: 80px;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    background-color: aliceblue;
}

@keyframes rollout {
    0% { transform: translateX(100px); }
    100% { transform: none; }
  }
  
  .roll-out {
    animation: rollout 0.4s;
  }

  .roll-out1 {
    animation: rollout 0.5s;
  }


  .cardGroup1{

    animation: fadeInRight 0.5s ease-in-out;
  }
  .cardGroup2{
    animation: fadeInRight1 0.5s ease-in-out;
}
.cardGroup3{
    animation: fadeInRight2 0.5s ease-in-out;
}

@keyframes fadeInRight {
    from {
      opacity: 1;
      transform: translateX(600px);
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fadeInRight1 {
    from {
      opacity: 1;
      transform: translateX(100%);
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fadeInRight2 {
    from {
      opacity: 1;
      transform: translateX(100%);
    }
    to {
      opacity: 1;
    }
  }


  /* .parent {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    }
    
    .div1 { grid-area: 1 / 1 / 2 / 5; }
    .div2 { grid-area: 2 / 1 / 4 / 4; }
    .div3 { grid-area: 2 / 4 / 4 / 5; } */

    /* .parent {
      display: grid; 
      grid-template-columns: 1fr 1fr 1fr; 
      grid-template-rows: 1fr 1fr 1fr; 
      gap: 0px 0px; 
      grid-template-areas: 
        "r1 r1 r1"
        "r2 r2 r3"
        "r2 r2 r3"; 
      height: 100%; 
    }
    .div1 { grid-area: r1; }
    .div2 { grid-area: r2; }
    .div3 { grid-area: r3; } */
    .parent{
      display: flex;
      flex-direction: column;
      height: 100vh;
    }


    /* .cardContainer{
      position: relative;
      background-color: red;
    } */

    @media (max-width: 700px) { 
      .grid{
        overflow-x: scroll;
        position: relative;
        height: 320px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 2rem;
        padding: 0px 30px;
        /* background-color: #e8e8e2; */
        /* background-color: #E5E5E5; */
        background-color: #FAFAFA;
        padding-bottom: 20px;
        padding-top: 20px;
    }
    .cell{
        
        background-color: white;
        padding: 1.5rem;
        border-radius: 10px;
        cursor: pointer;
        bottom: 0;
    
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 264px;
        /* width: 264px;
        height: 103px; */
        
    }

    .cell-header{
      font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 160%;
  color: #18191D;
  /* or 40px */
  
  display: flex;
  align-items: center;
  letter-spacing: 0.002em;
  }
  .cell-subheader{
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 300;
      font-size: 10px;
      line-height: 160%;
      /* or 19px */
      color: #18191D;
      display: flex;
      align-items: center;
      letter-spacing: 0.002em;
      
   
  }
    .grid2{
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 2rem;
      border-top: solid 0.5px #E7E7E7;
      /* padding: 10px 30px; */
      background-color: white;
      /* height: window.inner-300px; */
      /* padding-bottom: 30px; */
      /* grid-auto-rows: minmax(min-content, max-content); */
  
  }
  .mobileHeight{
    height: 40vh;
  }
    }