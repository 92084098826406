.soldGrid{
    display: grid !important;
    grid-template-columns: 1.5fr 1fr 1.2fr 1fr 1fr 1fr 1fr !important;
    padding: 10px 50px;
    background-color: #FAFAFA;
    border-bottom: solid 0.5px #E7E7E7;
    & > div:not(:first-child){
        text-align: right;
        font-weight: 500;
    }
    & > div{
        font-weight: 500;
    } 
}
.soldGrid1{
    display: grid !important;
    grid-template-columns: 1.5fr 1fr 1fr 0.8fr 0.8fr 0.8fr 0.8fr !important;
    padding: 10px 50px;
    background-color: #FAFAFA;
    border-bottom: solid 0.5px #E7E7E7;
    font-size: 11px;
   
    & > div:not(:first-child){
        text-align: right;
        font-weight: 600;
    }
    & > div{
        font-weight: 600;
    } 
}


.soldUsersGrid{
   
    padding: 10px 50px;
    border-bottom: solid 0.5px #E7E7E7;
    height: 120px;
    .title{
        font-size: 0.9rem ;
        font-weight: 700;
        color: #18191D;
    }
    .subtitle{
        font-size: 18px !important;
        font-weight: 400;
        color: #18191D;
    }
    
    .subtitle1{
        font-size: 17px !important;
        font-weight: 400;
        color: #18191D;
    }
    & > div:not(:first-child){
        /* text-align: right; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: end; 
    }
    &:hover{
        & > div{
            -ms-transform: scale(1.02); /* IE 9 */
            -webkit-transform: scale(1.02); /* Safari 3-8 */
            transform: scale(1.02); 
            transition: 0.3s all ease-in-out;
            opacity: 1 !important;
        }
        cursor: pointer;
         background: whitesmoke;
         transition: 0.3s all ease-in-out;
    }
}

.soldUsersGrid1{
    display: grid !important;
    grid-template-columns: 1.5fr 1fr 1fr 0.8fr 0.8fr 0.8fr 0.8fr !important;
    padding: 10px 50px;
    border-bottom: solid 0.5px #E7E7E7;
    height: 100px;
    .title{
        font-size: 13px !important;
        font-weight: 700;
        color: #18191D;
    }
    .subtitle{
        font-size: 13px !important;
        font-weight: 400;
        color: #18191D;
    }
    
    .subtitle1{
        font-size: 12px !important;
        font-weight: 400;
        color: #18191D;
    }
    & > div:not(:first-child){
        /* text-align: right; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: end; 
    }
    &:hover{
        & > div{
            -ms-transform: scale(1.02); /* IE 9 */
            -webkit-transform: scale(1.02); /* Safari 3-8 */
            transform: scale(1.02); 
            transition: 0.3s all ease-in-out;
            opacity: 1 !important;
        }
        cursor: pointer;
         background: whitesmoke;
         transition: 0.3s all ease-in-out;
    }
}

