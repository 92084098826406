.hoverCoin{
    display: flex;
    align-items: center;
    padding-left: 30px;
    color: #18191D;
    font-weight: 700;
    cursor: pointer;
    opacity: 0.5;
    &:hover{
      opacity: 1 !important;
    }
  }