.thebreadcrumb {
    font-weight: 700;
    font-size: 10px;
    text-decoration-line: underline;
    color: #212224;
    cursor: pointer;
}

.crumb {
    font-size: 10px;
    cursor: pointer;
}



.defaultcard {
    font-weight: 600;
    font-size: 16px;
    color: #212224;
    margin-top: 50px;
}

.boxtext {
    font-weight: 500;
    font-size: 15px;
    color: #212224;
    border: 0.5px solid #E7E7E7;
    padding: 25px;
    margin-top: 20px;
    cursor: pointer;
}

.boxtext:hover {
    background: #FAFAFA;
    padding-left: 30px;
    transition: 0.3s all ease-in-out;
}

.thefonttext {
    margin: 0 !important;
}

.success-title {
    font-weight: 800;
    font-size: 25px;
    color: #182542;
}

.success-desc {
    font-weight: 300;
    font-size: 13px;
    line-height: 30px;
    color: #2E2D2D;
}

.thevalue {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
}

.tokendata {
    display: flex;
    justify-content: space-between;
    border: 0.5px solid #E7E7E7;
    border-radius: 10px;
    padding: 20px;
}

.numvalue {
    font-weight: 700;
    font-size: 15px;
    color: #182542;
}

.thecoin {
    font-weight: 600;
    font-size: 17px;
    color: #182542;
}

.thescrolldata{
    height: calc(100vh - 400px);
    overflow: scroll;
}