.approvebtn{
    background: #78D090;
    color: white !important;
    border-radius: 35px;
    padding: 10px 30px;
    width: 120px;
    display: flex;
    justify-content: center;
}

.rejectbtn{
    background: #EA0F0F;
    color: white !important;
    border-radius: 35px;
    padding: 10px 30px;
    width: 120px;
    display: flex;
    justify-content: center;
}