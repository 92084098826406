.rewardsTab {
  height: 100%;
  & > .tabsContainer {
    height: 50px;
    margin: 0;
    display: flex;
    width: 100%;
    overflow-x: scroll;
    // padding: 0 30px;

    & > .leftTabs {
      height: 100%;
      width: calc(100% - 150px);
      overflow-y: scroll;
      padding: 5px;
      display: flex;

      & > div {
        width: fit-content;
        padding: 0 30px !important;
        //   flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        line-height: 1;
        color: rgba(0, 10, 0, 0.5);
        font-size: 14px;
        font-weight: 400;
        height: 100%;
        cursor: pointer;

        &:hover {
          font-weight: 600;
        }
      }

      & > .selectedTab {
        opacity: 1;
        background: rgba(229,229,229, 0.25);
        border-radius: 5px;
        color: rgb(33, 37, 41);
        font-weight: 600;
      }
    }

    & > .rightTabs {
      width: 150px;
      padding-right: 5px;
      display: flex;

      & > div {
        height: 100%;
        border-left: 0.5px solid #e7e7e7;
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;

        & > img {
          cursor: pointer;
          width: 17px;
          height: 17px;
          opacity: 0.5;
        }

        & > svg {
          cursor: pointer;
          width: 20px;
          height: 20px;
          opacity: 0.5;
        }
      }
    }
  }

  & > .tableContainer {
    height: calc(100vh - 132px);
    position: relative;

    & > .headings {
      display: flex;
      padding: 10px 30px;
      height: 45px;
      background-color: #fafafa;
      border-bottom: solid 0.5px #e7e7e7;
      border-top: solid 0.5px #e7e7e7;
      font-size: 0.8rem;
      font-weight: 600;

      & > :nth-child(1) {
        width: 30%;
      }
      & > :nth-child(2) {
        width: 30%;
      }
      & > :nth-child(3) {
        width: 25%;
      }
      & > :nth-child(4) {
        width: 15%;
      }
    }

    .contentRewards{
      height: calc(100vh - 170px);
      overflow: scroll;
    & > .contentTable {
      overflow-y: scroll;
      & > div {
        display: flex;
        padding: 10px 30px;
        border-bottom: solid 0.5px #e7e7e7;
        height: 90px;
        font-size: 0.9rem;
        font-weight: 550;

        & > div {
          display: flex;
          align-items: center;
        }

        & > :nth-child(1) {
          width: 30%;
        }
        & > :nth-child(2) {
          width: 30%;
        }
        & > :nth-child(3) {
          width: 25%;
        }
        & > :nth-child(4) {
          width: 15%;

          & > div {
            background: #ffffff;
            border: 0.5px solid #e7e7e7;
            border-radius: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0.5rem 2.5rem;
            cursor: pointer;
            transition: all ease-in 0.2s;

            &:hover {
              background: rgb(75, 42, 145);
              color: white;
            }
          }
        }
      }
    }
  }
  }
}

.updateRewards {
  border-left: 0.5px solid #e7e7e7;
  border-top: 0.5px solid #e7e7e7;
  width: 400px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background: white;
  padding: 2rem 1rem;
  position: absolute;

  & > div {
    margin-bottom: 1.5rem;
  }

  & > .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.25rem;

    & > :nth-child(1) {
      font-size: 1.25rem;
      font-weight: 600;
      color: #212224;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    & > :nth-child(2) {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #4B9DDC;;
      border: 0.5px solid #e7e7e7;
      border-radius: 5px;
      width: 35px;
      height: 35px;
      color: white;
      font-weight: 700;
      cursor: pointer;
      transition: all ease-in 0.1s;

      &:hover {
        color: #4B9DDC;;
        border: 1px solid #4B9DDC;;
        background: white;
      }
    }
  }

  & > .currentValue {
    background: #ffffff;
    border: 0.5px solid #e7e7e7;
    border-radius: 5px;
    padding: 1.15rem 2rem;
    font-size: 0.9rem;
    font-weight: 550;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  & > .updateValue {
    background: #ffffff;
    border: 0.5px solid #e7e7e7;
    border-radius: 5px;
    font-size: 0.9rem;
    font-weight: 550;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > input {
      border-radius: 5px;
      padding: 1.15rem 2rem;
      outline: none;
      border: none;
      font-size: 0.9rem;
      font-weight: 550;
    }

    &::placeholder {
      color: #2e2d2d;
      opacity: 0.25;
    }
  }

  & > .updateBtn {
    background: #4B9DDC;;
    color: white;
    border-radius: 5px;
    padding: 1.15rem 2rem;
    font-size: 0.9rem;
    font-weight: 550;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    left: 1rem;
    right: 1rem;
    bottom: 0;
    transition: all ease-in 0.3s;
    display: flex;
    gap: 1rem;

    // &:hover {
    //   background: white;
    //   color: #4B9DDC;;
    //   border: 1px solid #4B9DDC;;
    // }

    & > .loaderContainer {
      // width: 100%;
      // height: 100vh;
      // position: fixed;
      background: transparent
        url("https://media.giphy.com/media/8agqybiK5LW8qrG3vJ/giphy.gif") center
        no-repeat;
      z-index: 1;

      & > .spinner {
        width: 17px;
        height: 17px;
        border: 2px solid;
        border-color: white transparent white transparent;
        border-radius: 50%;
        animation: spin-anim 1.2s linear infinite;
      }

      @keyframes spin-anim {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }
}
