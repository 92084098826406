@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.loadingAnimation {
  background-color: #fff8;
  // position: absolute;
  // left: 50;
  // right: 50;
  // top: 50;
  // bottom: 50;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  > div {
    height: 100%;
    width: 100%;
  }
  .logo {
    width: 50%;
  }
}
.animation{
  height: 100%;
  width: 100%;
  // display: flex;
  // justify-content: center;
  // align-items: center;

  // position: absolute;
  // top: 20%;
  // right: 5%;
}

// .svgContainer{
//   width: 300px;
//   height: 150px;
//   resize: both;
//   overflow: auto;
//   svg{ display: block;
//     margin: auto;
//     position: absolute;
//     left: 0;
//     // right: 50;
//     top: 0;
//     // bottom: 50;
//   }

// //   width: 30%;
// // position: absolute;
// // z-index: 1;
// // top: 50%;
// // left: 25%;
// // // transform: translate(-50%, -50%);
// // background: white;
// // padding: 70px;
// // opacity: 0.9;
// }

// .svgContainer{
// // display: flex;
// // justify-content: center;
// // align-items: center;
// // height: 300px;
// width: 100%;
// position: absolute;
// z-index: 1;
// top: 50%;
// left: 50%;
// transform: translate(-50%, -50%);
// background: white;
// padding: 70px;
// opacity: 0.9;
// }

.svgContainer{
  display: flex;
  align-items: center;
  height: 100%;
  svg{
    display: block;
    margin: 0 auto;
    text-align: center;
    width: 50%;
  }
}