@import "../../../../static/scss/mixin.scss";

.dashboardAppsMobile {
    display: none;
}

.readbtn {
    background: #FFFFFF;
    border: 0.5px solid #E7E7E7;
    border-radius: 15px;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: #18191D;
    padding: 8px 15px;
    width: 100px;
    cursor: pointer;
    @include btn-animate(black);
}

.actionbtn {
    background: #FFFFFF;
    border: 0.5px solid #E7E7E7;
    border-radius: 15px;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: #18191D;
    padding: 8px 15px;
    margin-left: 10px;
    width: 100px;
    cursor: pointer;
    @include btn-animate(black);
}

.btngrp{
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
}

@media only screen and (max-width: 600px) {
    .dashboardAppsMobile {
        display: block;
        // display: block;
        // background-color: red;
        // overflow-y: scroll;
        // overflow-x: scroll;
        // height: 40vh;
        padding-bottom: 20vh;
        // position: absolute;
        // top:20;
        // overflow-y: scroll;
        height: 100vh // justify-content: 'flex-start';
    }
}