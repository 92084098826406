@import "../../static/scss/mixin";

.modalStyle{
  padding: 50px; 
  padding-bottom: 0px;
  color: #182542;
  height: 600px;

  .headerSection{
    font-size: 30px;
    font-weight: 800;
    color: #18191D;
    text-align: center;
    padding-bottom: 40px;
  }
  
  .breadcrumb{
    // font-weight: bold;
    font-size: 13px;
    padding-bottom: 40px;
    .crumbs{
      &:hover{
        text-decoration: underline;
        cursor: pointer;
      }
    }
    
  }
  
  .titleSection{
    font-size: 16px;
    font-weight: 700;
    padding-bottom: 33px;
  }
  
  .menuCards{

    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 15px;
    border:  0.5px solid #E5E5E5;
    padding: 28px 31px;
    height: 86px;
    cursor: pointer;

    &:hover{
      background-color: #E7E7E780;
    }
  
  }

  .menuCards1{

    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 15px;
    border:  0.5px solid #E5E5E5;
    padding: 28px 31px;
    height: 86px;
  
  }

  .terminalCards{
    width: 100%;
    border-radius: 15px;
    width: 100%;
    border: 0.5px solid #E5E5E5;
    height: 65px;
    padding: 40px 20px;
    color: black;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 20px;

    &:hover{
      background-color: #E7E7E780;
    }

  }
  
  .footerBack{
  background-color: #E7E7E780;
  }
  




}

.footerBtns{
  border-top: 1px solid #E5E5E5;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 70px;
  .individualButtons{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 18px;
   
      @include btn-animate(white);
    
  }
}


.otpView {
  height: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2% 0;
  .label {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 10%;
  }
  .otpInputWrapper {
    width: 70%;
    justify-content: space-between;
    margin-bottom: 4%;
    > div {
      flex: 0 0 20%;
      height: 90px;
      .otpInput {
        width: 100% !important;
        height: 100%;
        border: 2px solid #E7E7E780;
        border-radius: 8px;
        font-size: 28px;
      }
    }
  }
}

