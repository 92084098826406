@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap");
@import "./static/scss/newConglomerate";

html, body{min-height:100%; outline: none;}
body{height:100vh;
    overflow: hidden;
    outline: none;
    ::-webkit-scrollbar {
        display: none;
      }
}
#root{
    height:100vh;
    outline: none;
}

body #root * {
    font-family: Montserrat;
    outline: none;
    box-sizing: border-box;
}

.grid-cap{
    position: relative;
    height: 100%;
    display: grid;
    grid-template-columns: 0.2fr 1fr 10.8fr;
}

.grid-cap-collapsed{
    position: relative;
    height: 100%;
    display: grid;
    grid-template-columns: 0.2fr 6fr;
}


.mobileWrapper{
    display: none;
  }

  .desktopWrapper{
    display: block;
  }

  @media only screen and (max-width: 600px) {
    .desktopWrapper{
        display: none !important;
      }
      .mobileWrapper{
        display: flex;
        flex-direction: column;
        overflow: hidden;
        // border-bottom: 0.5px solid #e7e7e7;
      }
  }