.drawernav {
    display: flex;
    justify-content: center;
    align-items: center;
}

.width100{
    width: 100%;
}

.subnavtitle {
    display: flex;
    width: 50%;
    padding-top: 15px;
    justify-content: center;
    align-items: center;
    border-bottom: 0.5px solid #E7E7E7;

    &.active {
        background-color: #F5F5F5;
    }

    &.left {
        border-right: 0.5px solid #E7E7E7;
    }

    &.right {
        border-left: 0.5px solid #E7E7E7;
    }
}

.subdrawercontent__header {
    display: flex;
    padding: 30px 20px;
}


.crumbs_data {
    font-size: 15px;
    text-decoration: underline;
}

.subdrawercontent__header__title {
    font-size: 15px;
    font-weight: 700;
    margin: 0px;
}

.article_title {
    margin: 0px;
}

.drawer_img {
    border-radius: 25px;
    border: 0.5px solid #E7E7E7;
    height: 50px;
    width: 50px;
}

.storyname {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 160%;
    display: flex;
    align-items: center;
    letter-spacing: 0.002em;
    color: #18191D;
}

.StoryInput {
    display: flex;
    height: 68px;
    flex: 0 0 68px;
    border: 1px solid #e7e7e7;
    margin: 20px 0 20px 0;
    position: relative;
}

.textStory {
    border: none;
    padding: 0px 30px;
}

.image-data {
    display: flex;
    align-items: center;
    width: 450px;
    justify-content: space-between;
}

.authorDrawer-title {
    width: 100%;
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding: 0px 20px;
    height: 100px;
}

.auth-title {
    font-weight: 600;
    font-size: 24px;
    color: #212224;
    margin: 0px;
}
.closeico-sty{
    padding: 13px;
    border-radius: 5px;
    background: #4B9DDC;;
    cursor: pointer;
}

.updateProductBtn{
    width: 100%;
    background: rgba(102, 105, 176, 1);
    border-radius: 35px;
    color: white;
    font-weight: 700;
    padding: 14px;
    margin-top: 10px;
    cursor: pointer;
    border: none;
    &:hover{
        transform: scale(1.1);
        transition: 0.3s ease-in-out;
    }
}

.bottomProductBtn{
    position: absolute;
    width: 350px;
    bottom: 20px;
}

.bottomData{
    background: white;
    width: 100%;
    padding: 20px 60px !important;
    text-align: center;
    bottom: 0px !important;
}

.flexImg {
    flex: 0 0 75% !important;
    max-width: 75% !important;
}

.flexProduct{
    flex: 0 0 45% !important;
    max-width: 45% !important;
}

.scrollstory {
    overflow-y: scroll;
    height: calc(100vh - 400px);
}

.filesUpload1{
    display: flex;
    height: 150px;
    flex: 0 0 150px;
    justify-content: space-between;
    padding-bottom: 30px;
    /* margin-bottom: 25px; */
    border-radius: 10px;
}
.ImgBox{
    margin-bottom: 0px !important;
}
.scrollDrawer {
    overflow-y: scroll;
    height: calc(100vh - 390px);
    padding: 0px 20px;
}

.productScrollDrawer{
    overflow-y: scroll;
    height: calc(100vh - 300px);
    padding: 0px 20px;
}

.storyBtn {
    background-color: white;
    position: absolute;
    width: 100%;
    height: 70px;
    bottom: 0;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-top: 0.5px solid #e7e7e7;
    display: flex;
    justify-content: space-between;
}

.btnStory {
    width: 100%;
    background: none;
    border: none;
    font-weight: 700;
    font-size: 20px;
    color: #212224;
    cursor: pointer;
}

.btnss {
    padding: 0px 20px;
}

.crtbtn {
    width: 100%;
    background: #4B9DDC;;
    border-radius: 10px;
    color: white;
    font-weight: 700;
    padding: 14px;
    margin-top: 10px;
    cursor: pointer;
}

.bckbtn {
    width: 100%;
    background: black;
    border-radius: 10px;
    color: white;
    font-weight: 700;
    padding: 14px;
    margin-top: 10px;
    cursor: pointer;
}