@import "../../../static/scss/mixin";
@import "../../../static/scss/colors";

.btn1{
    width: 159px;
height: 68px;

background: #FFFFFF;
border: 0.5px solid #E7E7E7;
display: flex;
justify-content: center;
align-items: center;
@include btn-animate(#18191D);
}
.btnSelected{
    cursor: pointer;
    width: 159px;
    height: 68px;
    color: #FFFFFF;
    background: #18191D ;
    border: 0.5px solid #E7E7E7;
    display: flex;
    justify-content: center;
    align-items: center;
    @include btn-animate(#FFFFFF);
}
.fullBtn{
    background: #050505;
    color: #FFFFFF;
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
    height: 80px;
    cursor: pointer;
    @include btn-animate(#FFFFFF);
}

.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #18191D;
    border-bottom: 16px solid #18191D;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
  
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

 
  .bond {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid $border-color;
    .coinDetail {
      display: flex;
      padding: 20px 30px;
      align-items: center;
      .dp {
        height: 40px;
        width: 40px;
        margin-right: 10px;
      }
      .name {
        font-size: 22px;
        font-weight: 600;
      }
    }
    .bondValues {
      display: flex;
      padding: 20px 30px;
      padding-top: 0;
      align-items: center;
      justify-content: space-between;
      .cost,
      .intrst,
      .days {
        font-size: 18px;
        font-weight: 600;
      }
    }
    > * {
      transition: all 400ms ease;
    }
    &:hover {
      > * {
        transform: scale(1.03);
        cursor: pointer;
      }
    }
    &.true {
      background: whitesmoke;
    }
  }