.list-data-bank {}

.table-bank {
    display: flex;
    border-bottom: 0.5px solid #E7E7E7;
    padding-right: 30px;
    padding-left: 30px;
}

.tab-row {
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    background: #E5E5E5;
    padding-right: 30px;
    padding-left: 30px;
    border-bottom: 0.5px solid #E7E7E7;

    h2 {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: #18191D;
        margin-bottom: 0px;
        padding-right: 2%;
        width: 20%;

        &:nth-child(3) {
            width: 30%;
        }

        &:nth-child(5) {
            width: 10%;
        }
    }
}

.row-bank {
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 20%;
    padding-right: 2%;

    &:nth-child(3) {
        width: 30%;
    }

    &:nth-child(5) {
        width: 10%;
    }

    img {
        height: 33px;
        margin-right: 10px;
    }

    h1 {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: #2E2D2D;
    }

    p {
        margin-bottom: 0;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 300;
        font-size: 10px;
        line-height: 12px;
        color: #2E2D2D;
    }
}