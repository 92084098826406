.container1{
  background-color: #FAFAFA;
  height: 100vh;
  overflow-y: scroll;
}
.headers{
  font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 22px;
padding: 25px;

display: flex;
align-items: center;

color: #18191D;
}

.cardContainer{
  // style={{ display: "flex", overflowX: "scroll",  }}

  display: flex;
  overflow-x: scroll;
  border: 0.5px solid #E7E7E7;
  border-width: 0.5px 0px 0.5px 0px;
}

.card{
min-width: 21vw;
height: 140px;
left: 280px;
top: 174px;

background: #FFFFFF;
border-right: 0.5px solid #E7E7E7;
display: flex;
flex-direction: column;
padding-left: 25px;
justify-content: center;
// align-items: center;
.title{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;
  display: flex;
  align-items: center;
  
  color: #18191D;
}
.subtitle{

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 12px;
  display: flex;
  align-items: center;
  padding-top: 10px;
  color: #18191D;
  
}
}

.buttonSelected{
  padding:0px 15px;
height: 25px;

background: #18191D;
border: 0.5px solid #18191D;
border-radius: 5px;

font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 10px;
line-height: 12px;
display: flex;
align-items: center;
justify-content: center;

color: #FFFFFF;
margin-right: 10px;
}

.buttonNotSelected{
  padding:0px 15px;
height: 25px;

background: #FAFAFA;
border: 0.5px solid #5F6163;
border-radius: 5px;
font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  // line-height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
margin-right: 10px;
color: #18191D;
&:hover{
  padding:0px 15px;
  height: 25px;
  
  background: #18191D;
  border: 0.5px solid #18191D;
  border-radius: 5px;
  
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  // line-height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #FFFFFF;
}
}


@media only screen and (max-width: 600px) {

  .cardContainer{
    // style={{ display: "flex", overflowX: "scroll",  }}
  
    display: flex;
    overflow-x: scroll;
    border: 0.5px solid #E7E7E7;
    border-width: 0.5px 0px 0.5px 0px;
  }
  
  .card{
  min-width: 300px;
  height: 140px;
  left: 280px;
  top: 174px;
  
  background: #FFFFFF;
  border-right: 0.5px solid #E7E7E7;
  display: flex;
  flex-direction: column;
  padding-left: 25px;
  justify-content: center;
  // align-items: center;
  .title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
    display: flex;
    align-items: center;
    
    color: #18191D;
  }
  .subtitle{
  
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 12px;
    display: flex;
    align-items: center;
    padding-top: 10px;
    color: #18191D;
    
  }
  }

}