.scrollbarSideDraw {
    padding: 0px 30px;
    padding-bottom: 30px;
}

.navs-disp {
    display: flex;
    font-family: Montserrat;
}

.navs-data {
    width: 50%;
    text-align: center;
    height: 55px;
    align-items: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-right: 0.5px solid #E7E7E7;
    border-bottom: 0.5px solid #E7E7E7;
    font-weight: 400;
    font-size: 16px;
}

.disp-nav {
    font-weight: 400;
    font-size: 16px;
}

.active-tab {
    font-weight: 700;
    font-size: 16px;
    background: #FAFAFA;
}

.profile-data {
    padding: 45px 30px;
}

.user-profile {
    width: 49px;
    height: 49px;
    border-radius: 30px;
}

.prof-align {
    display: flex;
}

.select-name {
    font-weight: 700;
    font-size: 25px;
    color: #212224;
}

.drawer-crumb {
    font-weight: 400;
    font-size: 9px;
    color: #212224;
    margin-top: -22px;
    cursor: pointer;
}

.prof-text {
    margin-left: 10px;
}

.active-crumb {
    font-weight: 700;
    text-decoration: underline;
}

.back-right,
.close-img {
    border: 0.5px solid #E7E7E7;
    width: 40px;
    height: 40px;
    padding: 10px;
    cursor: pointer;

}

.icon-imgs {
    overflow: hidden;
    position: relative;
    height: 40px;
}

.icon-imgs::after {
    background: #212224;
    content: "";
    height: 200px;
    left: -105px;
    opacity: 1;
    position: absolute;
    top: -70px;
    width: 20px;
    transition: all .55s cubic-bezier(.19, 1, .22, 1);
    -webkit-transform: rotate(35deg);
    transform: rotate(35deg);
    z-index: -10;
}

.icon-imgs:hover::after {
    left: 155%;
    transition: all .55s cubic-bezier(.19, 1, .22, 1);
    z-index: 10;
}

.icons-sty {
    margin-left: auto;
    margin-top: 8px;
    display: flex;
}

.title-text {
    font-weight: 600;
    font-size: 16px;
    color: #212224;
}

.user-title {
    margin-top: 50px;
}

.usertyp-border {
    border: 0.5px solid #E7E7E7;
    display: flex;
    height: 83px;
    align-items: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

// .usertyp-border::after {
//     background: #212224;
//     content: "";
//     height: 200px;
//     left: -105px;
//     opacity: 1;
//     position: absolute;
//     top: -95px;
//     width: 40px;
//     transition: all 0.55s cubic-bezier(0.19, 1, 0.22, 1);
//     transform: rotate(35deg);
//     z-index: -10;
// }

// .usertyp-border:hover::after {
//     left: 155%;
//     transition: all .55s cubic-bezier(.19, 1, .22, 1);
//     z-index: 10;
// }

.usertyp-border:hover {
    background: #e5e5e5;
    padding-left: 10px;
    transition: 0.4s all ease;
}

.type-txt {
    font-weight: 500;
    font-size: 15px;
    color: #212224;
    margin-top: 16px;
    margin-left: 10px;
}

.type-img {
    margin-left: 30px;
}

/// New Global Style

.right-drawer-hidden {
    position: absolute;
    background: white;
    top: 123px;
    right: 0;
    overflow: hidden;
    width: 450px;
    border-left: 1px solid #e5e5e5;
    margin-top: -1px;
    transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    background: white;
    z-index: 1;
    transform: TranslateX(100%);
}

.right-drawer-visible {
    position: absolute;
    background: white;
    top: 123px;
    right: 0;
    overflow: hidden;
    width: 450px;
    border-left: 1px solid #e5e5e5;
    margin-top: -1px;
    transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    background: white;
    z-index: 10;
    transform: TranslateX(0%);
}

.backButton {
    width: 39px;
    height: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.5px solid #E7E7E7;
    cursor: pointer;
}

.breadcrumb {
    // font-weight: bold;
    font-weight: 400;
    font-size: 13px;
    padding-bottom: 40px;

    .crumbs {
        font-weight: 400;

        &:hover {
            font-weight: 600;
            cursor: pointer;
        }
    }

    .crumbSelected {
        text-decoration: underline;
        font-weight: 600;
    }

}

//   .breadcrumbSelected{
//     font-weight: bold;
//     font-size: 13px;
//     padding-bottom: 40px;
//     .crumbs{
//       &:hover{
//         text-decoration: underline;
//         cursor: pointer;
//       }
//     }

//   }

// .breadcrumb{
//     font-family: 'Montserrat';
//     font-style: normal;
//     font-weight: 400;
//     font-size: 10px;
//     line-height: 12px;
//     display: flex;
//     align-items: center;
//     padding-top: 6px;
// }
// .breadcrumbSelected{
//     font-family: 'Montserrat';
//     font-style: normal;
//     font-weight: 700;
//     font-size: 10px;
//     line-height: 12px;
//     display: flex;
//     align-items: center;
//     text-decoration-line: underline;
//     padding-top: 6px;
// }

.sidebarTitle {
    padding-top: 20px;
    padding-bottom: 20px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #464B4E;
}

.sidebarCard {
    border: 0.5px solid #E7E7E7;
    padding: 30px;
    font-weight: 500;
    font-size: 15px;
    display: flex;
    align-items: center;
    margin-top: 20px;
    position: relative;

    &:hover {
        background: whitesmoke;
        padding-left: 40px;
        transition: 0.2s all ease;
        cursor: pointer;
    }
}

.sidebarCardDisabled {
    border: 0.5px solid #E7E7E7;
    padding: 30px;
    font-weight: 500;
    font-size: 15px;
    display: flex;
    align-items: center;
    margin-top: 20px;
    opacity: 0.5;
}


// mcbAppEdit

.lableName {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #182542;
    padding-bottom: 20px;
}

.inputWrap1 {
    // margin-bottom: 50px;
    height: 62px;
    width: 100%;
    padding: 20px;
    border: 0.5px solid #e7e7e7;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    overflow-x: scroll;

    input {
        border: none;
        width: 100%;

    }
}

.editIcon {
    position: absolute;
    top: 48%;
    right: 0;
    border: 0.5px solid #e7e7e7;
    width: 37px;
    height: 37px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: -15px;

    &:hover {
        background-color: whitesmoke;
        transform: scale(1.05);
        cursor: pointer;
        transition: all 300ms ease-in-out;
    }
}




.logoCard {
    position: relative;
    width: 103px;
    height: 80px;
    border: 0.5px solid #E7E7E7;
    display: flex;
    justify-content: center;
    align-items: center;

    .editIcon1 {
        position: absolute;
        top: 0;
        right: 0;
        margin-right: -15px;
        margin-top: -15px;
        border: 0.5px solid #e7e7e7;
        width: 37px;
        height: 37px;
        background-color: white;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            background-color: whitesmoke;
            transform: scale(1.05);
            cursor: pointer;
            transition: all 300ms ease-in-out;
        }
    }
}

.iconLabel {
    // position:absolute;
    // bottom: 0;
    width: 103px;
    text-align: center;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    // text-align: center;  
    padding-top: 10px;
}

.colourInput {
    display: grid;
    grid-template-columns: 7fr 2fr;
    border: 0.5px solid #e7e7e7;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    height: 62px;

    &>div:first-child {
        display: flex;
        align-items: center;
        padding-left: 20px;
    }
}


.footerBtns {
    background-color: white;
    position: absolute;
    // width: 400px;
    width: 100%;
    height: 70px;
    bottom: 0;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;

    display: grid;
    grid-template-columns: 1fr 1fr;


    border-top: 0.5px solid #e7e7e7;
    display: flex;
    justify-content: space-between;

    &>div:first-child {
        width: 50%;

        opacity: 0.3;
        border-right: 0.5px solid #e7e7e7;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            background-color: whitesmoke;
            transition: all 300ms ease-in-out;
            cursor: pointer;
            opacity: 1;
        }
    }

    &>div:not(:first-child) {
        width: 50%;

        border-left: 0.5px solid #e7e7e7;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            background-color: #182542;
            color: white;
            transition: all 300ms ease-in-out;
            cursor: pointer;
        }
    }
}

.filesUpload {
    padding-top: 20px;
    display: flex;
    height: 170px;
    flex: 0 0 170px;
    justify-content: space-between;
    padding-bottom: 30px;
    margin-bottom: 25px;

    &.last {
        margin-bottom: 30px;
    }

    .fileInp {
        border: 1px solid #e7e7e7;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            height: 55%;
            width: auto;
            cursor: pointer;

            &:hover {
                transform: scale(1.03);
            }

            &.true {
                transform: scale(1);
                height: 100%;
                width: 100%;
                object-fit: contain;

                &.white {
                    background: white;
                }
            }
        }

        input {
            display: none;
        }

        .text {
            font-weight: 500;
            position: absolute;
            bottom: -25px;
            font-size: 13px;
            text-align: center;
        }

        .hovTxt {
            display: none;
            font-size: 13px;
        }

        &:hover {
            .hovTxt {
                display: flex;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                justify-content: center;
                align-items: center;
                background: #fffa;
                font-weight: bold;
                text-align: center;
            }
        }

        &.icon {
            flex: 0 0 26%;
            max-width: 26%;
        }

        &.cover {
            flex: 0 0 42%;
            max-width: 42%;
        }

        &.fullLogo {
            flex: 0 0 48%;
            max-width: 48%;
        }
    }
}

.coinsdata {
    font-size: 28px;
    font-weight: 700;
    color: #212224;
    margin-bottom: 0;
    margin-top: 15px;
}


.boxdisp {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #212224;
    margin: 0px 30px;
    border: 0.5px solid #E7E7E7;
    padding: 25px 15px;
    cursor: pointer;
}

.assetDispText {
    margin: 0 35px;
    height: calc(100vh - 175px);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 300;
    font-size: 13px;
    color: #2E2D2D;
    text-align: center;
}