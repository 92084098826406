@import "../../../static/scss/mixin";

.btn2{
    width: 50%;
height: 68px;

background: #FFFFFF;
border-left: 0.5px solid #E7E7E7;
font-weight: 600;
display: flex;
justify-content: center;
align-items: center;
}
.btnSelected1{
    cursor: pointer;
    width: 50%;
    height:100%;
    font-weight: 700;
    color: #FFFFFF;
    background: #18191D ;
    // border: 0.5px solid #18191D;
    display: flex;
    justify-content: center;
    align-items: center;
    @include btn-animate(#FFFFFF);
}
.fullBtn{
    background: #050505;
    color: #FFFFFF;
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
    height: 80px;
    cursor: pointer;
    @include btn-animate(#FFFFFF);
}

.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #18191D;
    border-bottom: 16px solid #18191D;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
  
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  // .btnSubmitDisabled{

  // }

  // .btnSubmitEnabled{

  // }