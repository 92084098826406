.assetfields {
    margin: 0px 30px;
}

.heightscroll{
    height: calc(100vh - 360px);
    overflow: scroll;
}

.assettext {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #182542;
}

.assetinput {
    border: 0.5px solid #E7E7E7;
    width: 100%;
    padding: 15px;
}

.buttonaction {
    width: 100%;
    display: flex;
    position: fixed;
    bottom: 0;
}

.leftbtn,
.rightbtn {
    width: 50%;
}

.backbtn {
    width: 100%;
    border: 0.5px solid #E7E7E7;
    background: white;
    color: black;
    padding: 20px;
    font-weight: 700;
    cursor: pointer;
}

.savebtn {
    width: 100%;
    border: 0.5px solid #E7E7E7;
    background: white;
    color: black;
    padding: 20px;
    font-weight: 700;
    cursor: pointer;
}

.assetDispText {
    margin: 0 35px;
    height: calc(100vh - 175px);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 300;
    font-size: 20px;
    color: #2E2D2D;
    text-align: center;
}

.thedistribdata {
    border: 0.5px solid #E7E7E7;
    width: 50%;
    padding: 20px;
    font-weight: 700;
}

.changedistrib {
    border: 0.5px solid #E7E7E7;
    width: 50%;
    padding-left: 18px;
    border-left: none;
    height: 63.6px;
    font-size: 16px;
    font-weight: 700;
    color: #182542;
}

.changedistrib::placeholder {
    font-size: 16px;
    font-weight: 700;
    color: #182542;
    opacity: 0.25;
}