.alignleft{
    text-align: left !important;
    align-items: start !important;
}
.thefullscroll{
    height: calc(100vh - 162px);
    overflow: scroll;
}

.theinput-box{
    width: 100%;
    border: 0.5px solid #E7E7E7;
    padding: 21px;
}

.filetype{
    font-weight: 500;
    font-size: 17px;
    color: #212224;
    border: 0.5px solid #E7E7E7;
    padding: 25px;
    cursor: pointer;
    width: 159px;
    text-align: center;
}

.theiconupld{
    border: 0.5px solid #E7E7E7;
    padding: 30px
}

.addbtn{
    width: 100%;
    text-align: center;
    background: #18191D;
    color: white;
    bottom: 0;
    position: fixed;
    margin-left: -30px;
    align-items: center;
    height: 50px;
}


.doctext{
    margin-top: 13px;
}