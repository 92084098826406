.cardTop{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 30px;
    height: 97px;
    padding-right: 75px;


    .userName{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #050505;
    }
    .userNameSubtitle{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 200;
        font-size: 12px;
        line-height: 15px;
        display: flex;
        align-items: center;
        color: #050505;
        
    }
}

.cardBottom{
    height: 253px;
    border: 0.5px solid #E7E7E7;
    border-width: 0.5px 0px 0.5px 0px;
    display: flex;
    overflow-x:scroll;
    padding-right: 75px;
}

.assetName{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 160%;
    /* or 32px */

    display: flex;
    align-items: center;
    letter-spacing: 0.002em;
    padding-left: 10px;
    color: #18191D;
}

.cards{
    flex: 0 0 392px;
    // display: flex;
    // display: inline-block;
    // flex-direction: column;
  
    padding: 30px;
    border-right: 0.5px solid #E7E7E7;
    height: 100%;
    &:hover{
        -ms-transform: scale(1.02); /* IE 9 */
        -webkit-transform: scale(1.02); /* Safari 3-8 */
        transform: scale(1.02); 
        transition: 0.3s all ease-in-out;
        opacity: 1 !important;
        cursor: pointer;
        background: whitesmoke;
    }
}
.clickable{
    &:hover{
        -ms-transform: scale(1.02); /* IE 9 */
        -webkit-transform: scale(1.02); /* Safari 3-8 */
        transform: scale(1.02); 
        transition: 0.2s all ease-in-out;
        opacity: 1 !important;
        // font-weight: 700;
    }
}
.statTitle{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 160%;
    /* or 26px */

    display: flex;
    align-items: center;
    letter-spacing: 0.002em;

    color: #18191D;
}
.statSubtitle{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 11px;
    line-height: 160%;
    /* or 16px */
opacity: 0.8;
    display: flex;
    align-items: center;
    letter-spacing: 0.002em;

    color: #18191D;
}

.leftAlign{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.rightAlign{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}


// Bond Hash CSS

.bondHashTitle{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 17px;
display: flex;
align-items: center;

color: #050505;
}

.fade-enter {
    // max-height: 0;
    opacity: 0;
  }
  
  .fade-enter-active {
    // max-height: 30px;
    opacity: 1;
    transition: all 500ms;
  }
  
  .fade-exit {
    // max-height: 30px;
    opacity: 1;
  }
  
  .fade-exit-active {
    // max-height: 0;
    opacity: 0;
    transition: all 500ms;
  }
   