.listGrid{
    display: grid ;
    // grid-template-columns: 1fr 1fr 1fr 1fr 1fr ;
    padding: 10px 30px;
    height: 45px;
    background-color: #FAFAFA;
    border-bottom: solid 0.5px #E7E7E7;
    font-size: 0.8rem;
    // font-size: 1.6vh;
// font-size: clamp(0.8rem, 0.8rem + 12 * (100vw - 400px) / 800, 0.9rem);
    & > div:not(:first-child){
        text-align: right;
        font-weight: 600;
  
    }
    & > div{
        font-weight: 600;
       
    } 
}


.listGridMobile{
    display: grid ;
    // grid-template-columns: 1fr 1fr 1fr 1fr 1fr ;
  
    font-size: 0.8rem;
    // font-size: 1.6vh;
// font-size: clamp(0.8rem, 0.8rem + 12 * (100vw - 400px) / 800, 0.9rem);
    & > div:not(:first-child){
        text-align: right;
        font-weight: 600;
        padding: 10px 30px;
        height: 45px;
        background-color: #FAFAFA;
        border-bottom: solid 0.5px #E7E7E7;
    }
    & > div{
        font-weight: 600;
        padding: 10px 30px;
        height: 45px;
        background-color: #FAFAFA;
        border-bottom: solid 0.5px #E7E7E7;
    } 
}


.widthAdjust{
    width: calc(100vw - 300px);
    overflow-x: scroll;
}



.listDataGrid{
    display: grid ;
    // grid-template-columns: 1fr 1fr 1fr 1fr 1fr ;
    padding: 10px 30px;
    border-bottom: solid 0.5px #E7E7E7;
    height: 90px;
    .title{
        font-size: 0.9rem;
        font-weight: 600;
        color: #18191D;
    }
    .subtitle{
        font-size: 0.65rem ;
        font-weight: 400;
        color: #18191D;
    }
    
    .subtitle1{
        font-size: 10% ;
        font-weight: 400;
        color: #18191D;
    }
    & > div:not(:first-child){
        /* text-align: right; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: end; 
    }
    &:hover{
        & > div{
            -ms-transform: scale(1.02); /* IE 9 */
            -webkit-transform: scale(1.02); /* Safari 3-8 */
            transform: scale(1.02); 
            transition: 0.3s all ease-in-out;
            opacity: 1 ;
        }
        cursor: pointer;
         background: whitesmoke;
         transition: 0.3s all ease-in-out;
    }
}

.listDataGridMobile{
    display: grid ;
    // grid-template-columns: 1fr 1fr 1fr 1fr 1fr ;
   
    .title{
        font-size: 0.9rem;
        font-weight: 700;
        color: #18191D;
    }
    .subtitle{
        font-size: 0.65rem ;
        font-weight: 400;
        color: #18191D;
    }
    
    .subtitle1{
        font-size: 10% ;
        font-weight: 400;
        color: #18191D;
    }
    & > div:not(:first-child){
        /* text-align: right; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: end; 
        padding: 10px 30px;
        border-bottom: solid 0.5px #E7E7E7;
        height: 90px;
    }
    & > div {
        padding: 10px 30px;
        border-bottom: solid 0.5px #E7E7E7;
        height: 90px;
    }
    &:hover{
        & > div{
            -ms-transform: scale(1.02); /* IE 9 */
            -webkit-transform: scale(1.02); /* Safari 3-8 */
            transform: scale(1.02); 
            transition: 0.3s all ease-in-out;
            opacity: 1 ;
        }
        cursor: pointer;
         background: whitesmoke;
         transition: 0.3s all ease-in-out;
    }
}

.userDetail{
    padding-top: 10px;
}