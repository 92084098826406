.headtitle {
    font-weight: 400;
    font-size: 12px;
    text-decoration-line: underline;
    color: #18191D;
}

.headtext {
    font-weight: 700;
    font-size: 16px;
    color: #18191D;
}

.licenseDataGrid {
    display: grid;
    padding: 10px 30px;
    height: 90px;
}

.thebtm-hr {
    border: none;
    border-bottom: 0.5px solid #E7E7E7;
}

.imgtopmarg {
    margin-top: -3px;
}

.thefontt {
    font-size: 12px !important;

    @media screen and (max-width : 1450px) {
        font-size: 10px !important;
    }

    @media screen and (max-width : 1250px) {
        font-size: 8px !important;
    }
}